<template>
    <div class="card card-default" :class="loading ? 'whirl' : ''">
        <div class="card-header bg-gray-lighter text-bold">Sponsor</div>
        <div class="card-body">

          <form @submit.prevent="updateSponsor()">
            <div class="form-group">
              <label>New Sponsor Name</label>
              <input class="form-control" type="text" v-model="sponsor"/>
            </div>

            <button class="btn btn-info" type="submit">Change Sponsor</button>
          </form>
        </div>
    </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      sponsor: '',
      loading: false,
    }
  },
  methods: {
    updateSponsor() {
      this.loading = true;
      this.$auth.requestVerfificationCode().then(code => {
        this.$api.update('user/team/change-sponsor', {sponsor: this.sponsor, verification_code: code.verification_code}).then(response => {
          this.$router.push({name: 'Dashboard'});
          this.$swal.fire('Sponsor changed!');
        }).catch(error => {
          this.$api.showErrorMessage(error);
          this.loading = false;
        });
      });
    },

  }
}
</script>